#calendarReminders {
	i.fa-circle {
		cursor: pointer;
	}

	.form-row {

		flex-flow: row;
		flex-wrap: nowrap;
		align-content: stretch;

		&.form-row-remove {
			margin-bottom: 0;
		}

		i {
			margin-left: 10px;
		}

		input[type=text] {
			width: calc(50% - 80px);
			margin: auto;
		}

		&.form-row-header {
			div {
				width: calc(50% - 80px);
				margin: auto;

				&:first-child,
				&:last-child {
					width: 30px;
					margin: 10px;
				}
			}

		}
	}

	.reminders-other .form-row {
		flex-flow: column-reverse;
	}
}


#calendarItems {
	h3 {
		margin-top: 0;
	}

}

#calendar {
	@include no-select;
}

#calendar-prev,
#calendar-next {
	opacity: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 10000;
	width: 120px;
	touch-action: none;
	pointer-events: none;

	i {
		padding-left: 10px;
		position: absolute;
		top: 50%;
		font-size: 5em;
		opacity: 0.5;
	}
}

#calendar-prev {
	background: rgb(95, 47, 144);
	background: linear-gradient(90deg, rgba(95, 47, 144, 0) 1%, rgba(95, 47, 144, 0.9248074229691877) 5%, rgba(95, 47, 144, 0.8491771708683473) 44%, rgba(95, 47, 144, 0) 100%);
	margin-left: -20px;
}

#calendar-next {
	right: 0px;
	background: rgb(95, 47, 144);
	background: linear-gradient(90deg, rgba(95, 47, 144, 0) 0%, rgba(95, 47, 144, 0.8491771708683473) 56%, rgba(95, 47, 144, 0.9248074229691877) 95%, rgba(95, 47, 144, 0) 99%);
}

#calendar-filter {
	ul li {
		&.disabled {
			text-decoration: line-through;
		}
	}
}

#calendar,
#home,
.notification-center {

	.footer,
	.home-calendar {

		.day-categories {
			margin-left: 10px;

			>* {
				margin: 5px 4px;
			}
		}

		.day-appointment {
			padding-left: 0px !important;
			background: none !important;
			cursor: pointer;
			display: flex;
			height: initial !important;

			&.hidden {
				display: none;
			}

			padding: 5px;

			>i {
				border-left: 3px solid;
				width: 0px;
				overflow: hidden;
				order: 3;
				min-height: 40px;
				margin-right: 5px;
			}

			.day-appointment-combined {
				flex: 1;
				order: 4;
				overflow: hidden;

				.btn {
					margin-top: 4px;
				}
			}

			.day-appointment-name {
				display: block;

				i {
					display: none;
				}

				i.icon-remove {
					display: inline-block;
					margin-left: 5px;
				}
			}

			.day-appointment-description {
				display: block;
				font-weight: 100;
				margin-top: 4px;
			}

			.day-appointment-location {
				display: block;
				font-weight: 100;
				margin-top: 4px;
			}

			.day-appointment-time {
				width: 80px;
				order: 1;
				text-align: right;
				padding-right: 10px;
			}

			.day-appointment-time-end {
				font-weight: 100;
				position: absolute;
				width: 80px;
				text-align: right;
				padding-top: 20px;
				padding-right: 10px;
				order: 2;
				color: gray;
			}
		}
	}

}

#calendar {
	display: flex;
	flex-direction: column;

	&.calendar-week,
	&.calendar-week-full,
	&.calendar-day {
		.week:not(.week-selected) {
			display: none;
		}
	}

	&.calendar-day {
		.day:not(.selected) {
			display: none;
		}
	}

	.header {
		position: sticky;
		position: -webkit-sticky;
		/* Safari */
		z-index: 10000;
		margin-top: -10px;
		top: 0px; // for web see web.scss
		display: flex;
		padding: 10px;
		border-bottom: 0.5px solid #444;

		.navigation>div {
			display: inline-block;
			margin-left: 10px;
		}

		.today {
			margin-left: 10px;
		}

		.filter {
			width: 85px;
			text-align: right;
			margin-right: 10px;
		}

		.month {
			font-size: 1.3em;
			flex-grow: 1;
			text-align: right;
			font-weight: 100;
			margin-right: 5px;
		}

		.year {
			font-size: 1.3em;
			flex-grow: 1;
			font-weight: 100;
			margin-left: 5px;
		}

	}

	.footer {
		margin-top: 10px;
		border: 0.5px solid #444;
		min-height: 100px;
		display: flex;
		flex-flow: column;
	}

	.day-hour {
		display: none;
	}


	.calendar {
		//overflow: hidden;
		display: flex;
		flex-direction: column;

		.week {
			display: flex;
			flex-direction: row;

			.week-container {
				width: 30px;
				border: 0.5px solid #444;

				.week-number {
					text-align: center;
					font-weight: 100;
				}

				.week-hours {
					display: none;
				}
			}

			.day {
				border: 0.5px solid #444;
				flex: 1 1 0px;

				&.day-prev-month,
				&.day-next-month {
					color: gray;
				}

				.day-categories {
					font-size: 0.7em;
					line-height: 0.8em;
				}

				.day-appointments {
					overflow-x: hidden;
					overflow-y: auto;
				}
			}
		}

	}

	&.calendar-month {
		.calendar .week {
			.day {
				.day-appointments {
					.day-appointment-location {
						display: none !important;
					}
				}
			}
		}
	}

	&.calendar-week,
	&.calendar-month {
		.calendar .week {
			.week-fullday {
				display: none;
			}

			.day {
				.day-appointments {
					display: flex;
					flex-flow: column;

					.day-appointment {
						width: 100% !important;
						margin-left: 0px !important;
						background-color: rgba(0, 0, 0, 0) !important;
					}
				}
			}
		}
	}

	&.calendar-week-full,
	&.calendar-day {
		.footer {
			display: none;
		}

		.calendar .week {

			.week-container {
				width: 80px;

				.week-fullday {
					width: inherit;
					height: 60px;
					text-align: right;
					font-weight: 100;
					padding-top: 20px;
					padding-right: 5px;

					position: sticky;
					position: -webkit-sticky;
					/* Safari */
					z-index: 9999;
					top: 61px; // for web see web.scss
				}

				.week-number {
					width: inherit;
					padding: 2px 0;

					position: sticky;
					position: -webkit-sticky;
					/* Safari */
					z-index: 9999;
					top: 41px; // for web see web.scss
				}

				.week-hours {
					display: block;
					height: 1440px;
					text-align: right;

					.week-hour {
						font-weight: 100;
						height: 60px;
						width: 100%;
						margin-top: -9px;
						margin-bottom: 9px;
						padding-right: 5px;
					}
				}
			}

			.day {
				.day-header {
					padding-bottom: 2px;
					position: sticky;
					position: -webkit-sticky;
					/* Safari */
					z-index: 9999;
					top: 41px; // for web see web.scss
				}

				.day-fullday {
					height: 60px;
					border-bottom-width: 1px;
					border-bottom-style: solid;
				}

				.day-appointments {
					height: 1506px;
					position: relative;
					overflow: visible;

					.day-appointment {
						position: absolute;
						max-height: none;
						padding-right: 6px;
						padding-left: 2px;
						padding-top: 4px;
						width: calc(100% - 6px);
						border-left-width: 4px;
						border-left-style: solid;
					}

					.day-fullday {
						position: sticky;
						position: -webkit-sticky;
						/* Safari */
						z-index: 9999;
						padding-top: 5px;
						top: 61px; // for web see web.scss

						overflow-y: auto;

						.day-appointment.calendar-fullday {
							position: relative;
							max-height: inherit;
							padding-right: 0;
							padding-left: 0;
							padding-top: 0;
							width: 100%;
							border-left: none;
						}
					}


					>.day-appointment.calendar-fullday {
						display: none;
					}

					.day-hour {
						display: block;
						height: 60px;
						width: 100%;
						box-sizing: border-box;
						-moz-box-sizing: border-box;
						-webkit-box-sizing: border-box;
						border-bottom-width: 1px;
						border-bottom-style: solid;

						&.day-hour-23 {
							border-bottom: none;
						}
					}
				}
			}
		}
	}

	// Small screen
	@media only screen and (max-width: $largeScreen) {
		&:not(.calendar-day) {
			.calendar {
				.week {
					.week-number {
						padding-top: 10px;
					}

					.day {
						cursor: pointer;
						padding: 10px 0px;

						.day-name {
							text-align: center;

							>span {
								display: none;
							}
						}

						.day-number {
							text-align: center;
							font-size: 1.4em;
							font-weight: 100;
						}

						.month-name {
							display: none;
						}

						.day-categories {
							display: none;
						}

						.day-appointments {
							.day-appointments-colors {
								overflow: hidden;
								height: 17px;
								text-align: center;
								justify-content: center;

								i {
									padding: 2px;
									font-size: 0.7em;
								}
							}

							.day-appointment {
								display: none;
							}
						}
					}
				}
			}
		}

		&.calendar-day,
		&.calendar-week-full {
			.calendar {
				.week {
					.week-number {
						padding-top: 0px;
					}

					.day {
						overflow: hidden;
						cursor: pointer;

						.day-header {
							padding-top: 2px;
							white-space: nowrap;

							>* {
								display: inline-block;
							}
						}

						.month-name {
							font-weight: 100;
						}

						.day-appointment-description,
						.btn {
							display: none;
						}

						.day-name {
							padding-left: 5px;
							text-align: center;
							font-weight: 100;
						}

						.day-appointments {
							padding: 3px;

							.day-appointment {
								cursor: pointer;
								display: flex;

								&.canedit:hover {
									i.icon-calendar {
										display: none;
									}

									i.icon-remove {
										display: inline-block;
									}
								}

								>i {
									font-size: .3em;
									padding-top: 4px;
									flex: 0 0 10px;
									margin-right: 3px;
								}

								.day-appointment-combined {
									overflow: hidden;
									flex: 1 1 auto;
								}

								.day-appointment-name {
									overflow: hidden;
									text-overflow: ellipsis;
									word-break: break-all;

									i {
										font-size: 0.8em;
										padding-right: 3px;
										cursor: pointer;
									}

								}

								.day-appointment-location {
									display: block;
									font-weight: 100;
								}

								.day-appointment-time {
									white-space: nowrap;
									font-weight: 100;
									justify-content: flex-end;
									flex: 0 0 0;
								}

								.day-appointment-time-end {
									display: none;
								}
							}

							.day-appointments-colors {
								display: none;
							}
						}


					}
				}
			}
		}
	}

	// Large screen
	@media only screen and (min-width: $largeScreen) {
		.calendar {
			.week {
				.week-number {
					padding-top: 0px;
				}

				.day {
					cursor: pointer;

					.day-header {
						padding-top: 2px;
						white-space: nowrap;

						>* {
							display: inline-block;
						}
					}

					.day-appointment-description,
					.btn {
						display: none;
					}

					.day-name {
						padding-left: 5px;
						text-align: center;
						font-weight: 100;
					}

					.month-name {
						font-weight: 100;
					}

					.day-categories {
						overflow: hidden;

						.tag {
							margin-bottom: 0;
						}

						>* {
							margin-right: 5px;
						}
					}

					.day-appointments {
						clear: both;
						padding: 3px;
						display: block;
						height: 90px;

						.day-appointment {
							cursor: pointer;
							display: flex;

							&.canedit:hover {
								i.icon-calendar {
									display: none;
								}

								i.icon-remove {
									display: inline-block;
								}
							}

							max-height: 18px;
							min-height: 18px;

							>i {
								font-size: .3em;
								padding-top: 4px;
								flex: 0 0 10px;
								margin-right: 3px;
							}

							.day-appointment-combined {
								overflow: hidden;
								flex: 1 1 auto;
							}

							.day-appointment-name {
								overflow: hidden;
								text-overflow: ellipsis;
								word-break: break-all;

								i {
									font-size: 0.8em;
									padding-right: 3px;
									cursor: pointer;
								}

							}

							.day-appointment-location {
								display: block;
								font-weight: 100;
							}

							.day-appointment-time {
								white-space: nowrap;
								font-weight: 100;
								justify-content: flex-end;
								flex: 0 0 0;
							}

							.day-appointment-time-end {
								display: none;
							}
						}

						.day-appointments-colors {
							display: none;
						}
					}
				}
			}
		}
	}

	.categories {
		.tag {
			margin-top: 10px;
			margin-right: 5px;
		}
	}
}

#schedule {
	overflow-y: auto;

	.schedule-table {
		width: 100%;

		&.schedule-table-appointments {
			thead {
				tr {
					td {
						&:first-child {
							min-width: 120px;
						}
					}
				}
			}
		}

		&.schedule-table-categories {
			thead {
				tr {
					td {
						width: calc(100%/7);

						&:first-child {
							width: 35px;
						}
					}

				}
			}

			tbody {
				tr {
					height: 70px;

					td {
						width: calc(100%/7);

						&.week {
							width: 35px;
						}

						.tag {
							margin-top: 5px;
						}
					}
				}
			}
		}

		// Media query for smaller screens
		@media (max-width: $largeScreen) {
			&.schedule-table-categories {
				thead {
					tr {
						td {
							>span {
								display: none;
							}
						}
					}
				}
			}
		}

		thead {
			tr {
				td {
					padding: 5px 5px;
				}
			}
		}

		tbody {
			.schedule-date {
				padding: 5px 5px;
				font-size: 1.2em;
				font-weight: 200;

				span {
					display: inline-block;
					padding: 3px 5px;
				}

				.tag {
					float: right;
					margin-bottom: 0;
				}
			}

			tr {
				td {
					padding: 5px 5px;
				}
			}
		}
	}
}

#calendarItems {
	table {
		thead {
			tr {
				td {
					padding: 5px 5px;
				}
			}
		}

		tbody {
			tr {
				td {
					padding: 5px 5px;
				}
			}
		}

		.form-row {
			margin: 0;
		}
	}
}