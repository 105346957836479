.element-selector {
	@include no-select;
	
	ul.element-selector-selected {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		min-height: 96px;

		li {
			padding: 10px 2px;
				
			.element-selector-img-container {
				margin: 0 10px;
				position: relative;
				width:60px;
				height:60px;
			
				&[data-template]:hover {
					cursor: pointer;
				}
				
				&:hover {
					cursor: pointer;
				}

				i.fa-times-circle {
					top: 0px;
					border-radius: 50%;
					background: white;
					right: -5px;
					position: absolute;
				}
				
				.element-selector-img {
					@include rounded;
					width:60px;
					height:60px;
					object-fit: cover;
				}
				
				.flag-icon {
					@include rounded;
					width:60px;
					height:60px;
				}

				.element-selector-icon {
					display: inline-block;
					@include rounded;
					margin-bottom: 2px;
					width:60px;
					height:60px;
					text-align: center;
					i {
						font-size: 2.3em;
						line-height: 60px;
					}
				}
			}
			
			.element-selector-name {
				padding-top: 5px;
				font-size: 0.7em;
				text-align: center;
				word-break: break-word;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 80px;
			}
		}
	}


	ul.element-selector-elements, ul.element-selector-groups, ul.element-selector-languages {
		display: flex;
		flex-direction: column;
		// flex-wrap: wrap;
		
		li.element-selector-invisible {
			display: none;
		}

		li label {						
			width: auto !important;
			padding: 10px;
			display: flex !important;
			flex-direction: row;
			
			&:hover {
				cursor: pointer;
			}
			
			.element-selector-img-container {
				position: relative;
				
				input[type="checkbox"] {
					display: none;
					&:checked + i.fa-check-circle {
						display: inline-block;
					}
				}
				
				i.fa-check-circle {
					display: none;
					top: 0px;
					border-radius: 50%;
					background: white;
					right: -5px;
					position: absolute;
				}
				
				.element-selector-img {
					@include rounded;
					width:40px;
					height:40px;
					object-fit: cover;
				}
				
				.flag-icon {
					@include rounded;
					width:40px;
					height:40px;
				}
				
				.element-selector-icon {
					display: inline-block;
					@include rounded;
					margin-bottom: 2px;
					width:40px;
					height:40px;
					text-align: center;
					i {
						font-size: 1.5em;
						line-height: 40px;
					}
				}
			}
			
			.element-selector-info {
				padding-left: 10px;
			}
			
			.element-selector-name {
				padding-top: 5px;
				text-align: left;
				overflow: hidden;
				word-break: break-word;
				text-overflow: ellipsis;
				max-width: 100%;
			}
			
			.element-selector-organisations {
				font-size: 0.7em;
			}
		}
			
	}
}
