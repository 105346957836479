span.badge {
	text-align: center;
    border-radius: 10px;
    color:white;
    padding: 3px 6px;
    font-size: 0.8em;
   	background-color: #dc3545;
	position: absolute;
	top: -7px;
	margin-left: 4px;
	display: inline-block;

	&:empty {
		display: none;
	}
}

.card-status-badge {
	color: #fff;
   	background-color: #dc3545;
	min-width: 26px;
	width: auto !important;
	padding: 0px 5px;
	&:empty {
		display: none;
	}
	i {
		font-family: $text;
		font-weight: 400;
	}
}

ul.cards-style-list {
	.card-status-badge {
		min-width: 10px;
	}
}

.notification-badge {
	color: #fff !important;
	display: inline-block;
   	background-color: #dc3545;
	border-radius: 12px;
	float:right;
	padding: 3px 6px;

}

.comment-count {
	display: inline-block;
	border-radius: 12px;
	padding: 3px 6px;
}