.btn-group {
	margin-bottom: 5px;
}

.dropdown span.dd-button i.fa-caret-down {
	padding-left: 5px;
}

#backgroundImage {
	height: 100%;
	width: 100%;
	object-fit: contain;
	object-position: top;
	@include no-drag;
}

#shortlistFormationsEditor {
	@include no-select;
	& > div {
		height: calc(100vh - 75px);
		width: 100%;
		display: inline-block;
		position: relative;

		& > img {
			height: 100%;
			width: 100%;
			object-fit: contain;
			object-position: top;
			@include no-drag;
		}

		.shortlistPosition {
			color: #fff !important;
			position: absolute;
			transform-origin: left top;

			.shortlistDot {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background: rgba(#fff, 0.8);
				margin: 0 auto;
				margin-bottom: -10px;
			}

			.shortlistItem {
				display: flex;
				background: rgba(#000, 0.8);
				width: 200px;
				outline: 1px solid #fff;
				margin-top: 1px;
				> .shortlistItemEdit {
					position: absolute;
					right:5px;
					display:none;
				}
				&:hover {
					> .shortlistItemEdit {
						display: inline-block;
					}
				}

				> .shortlistItemLeft {
					width: 20px;
					padding: 5px;
					padding-top: 5px;

					> .shortlistItemState {
						width: 10px;
						height: 10px;
						border-radius: 50%;
					}
				}

				> .shortlistItemRight {
					padding-top: 3px;
					padding-right: 3px;
					padding-bottom: 3px;

					> .shortlistItemName {
						font-size: 1em;
						font-weight: 500;
						width: 177px;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						padding-bottom: 3px;

						span {
							font-weight: 200;
						}
					}

					> .shortlistItemClub {
						font-size: 1em;
						font-weight: 200;
						width: 177px;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}
				}
			}
		}
	}
}
.shortlistListsWrapper {
	overflow-y: auto;
	height: calc(100vh - 125px);
}

.draggable {
	cursor: move;
}


.shortlistLists {
	width: 100%;

	tr {
		> th {
			padding: 10px;
			text-align: left;
			font-weight: 600;
		}

		> .shortlistListActions {
			white-space: nowrap;
			text-align: right;
			padding:0;
			i {
				padding:10px 5px;
			}
		}


		> td {
			padding: 10px;
			border-bottom: 1px solid;

			&.playerBirthdate {
				white-space: nowrap;
			}

			&.state {
				width: 1px;
				padding: 5px;
			}
			//
			// &.playerUserType {
			// 	width:5px;
			// 	padding:2px;
			// }

		}
	}
}

.shortlist-search-row {
	input {
		width:250px !important;
		max-width:100%;
	}
}

.shortlists-add-user {

	.shortlists-add-user-search {
		display: flex;

		* {
			display: inline-block;
		}

		input {
			flex: 1;
    		height: 2.1em;
    		padding: 0 0.75em;
			background: white;
			color: #000;
			border-radius: 5px;
    		box-sizing: border-box;
    		width: auto;
    		transition: border-color .1s,box-shadow .1s;
    		border: 1px solid #d9d9d9;
    		border-radius: 0.1875em;
    		box-shadow: inset 0 1px 1px rgba(0,0,0,.06), 0 0 0 3px rgba(0,0,0,0);
    		color: inherit;
    		font-size: 1.125em;
		}

		button {
			margin: 0 0 0 1em;
		}
	}

	.shortlistLists {
		tr {
			cursor: pointer;
			&:hover {
				background: #d9d9d9;
			}

			td {
				text-align: left;
			}
		}
	}
}

.swal-form {
	display: flex;
	flex-direction: column;
	text-align: left;

	label {
   		font-size: 1.125em;
		color: #929292
	}
	input, select {
		height: 2.1em;
		padding: 0 0.75em;
		background: white;
		color: #000;
		border-radius: 5px;
		box-sizing: border-box;
		width: auto;
		transition: border-color .1s,box-shadow .1s;
		border: 1px solid #d9d9d9;
		border-radius: 0.1875em;
		box-shadow: inset 0 1px 1px rgba(0,0,0,.06), 0 0 0 3px rgba(0,0,0,0);
		color: inherit;
		font-size: 1.125em;
		margin-bottom: 0.5em;
		&:disabled {
			background:#ddd;
			cursor: not-allowed;
		}
		&.checkbox {
			height:auto;
		}
		&[type=color] {
			width:100px;
		}
	}
	.swal-inline {
		display: flex;
    	align-items: center;
		gap: 10px;
	}

}