ul.tags-container {

	border: 1px solid #d9d9d9; 
	padding: 5px;
	border-radius: 4px;
	display: flex;
	flex-wrap: wrap;
	
	li {
		padding: 5px;
		border-radius: 4px;
		border: 1px solid #000;
		margin: 5px;
		cursor: pointer;
	}	
}

.tag {
	display: inline-block;
	padding: 3px 5px;
	border-radius: 5px;
	margin-bottom: 5px;
}