.swal2-html-container {
    > * {
        margin-left: 0;
        margin-right:0;
    }
    select.swal2-select {
        font-size: 1em;
        width:100%;
    }
    textarea.swal2-textarea {
        width:100%;
        height:100px;
        padding:10px;
    }
    button.swal2-styled {
        margin: 0.3125em;
        padding: 0.625em 2em;
        box-shadow: none;
        font-weight: 500;
    }
}