#analyser-subvideo-window {
	position: fixed;
	z-index: 1;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	display: none;
	
	
	.analyser-subvideo-window {
		backdrop-filter: blur(10px);
		-webkit-backdrop-filter: blur(10px);
		background-color: rgba(0,0,0,.5);
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-around;
	
		#analyser-subvideo {
			width: 70%;
			height: 70%; 
		}
	}
	
	.vjs-icon-placeholder.fas {
		font-size: 0.9em;
		&::before {
			line-height: 1.8;
		}
	}
}

#analyser-report-window {
	position: fixed;
	z-index: 1;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	display: none;
	
	.analyser-report-window {
		backdrop-filter: blur(10px);
		-webkit-backdrop-filter: blur(10px);
		background-color: rgba(0,0,0,.5);
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-around;
	
		#analyser-report {
			padding: 40px 20px;
			width: 70%;
			background: white;
			overflow: auto;
			margin-top: 40px;
			max-height: 80%;
				
			ul {
				color: black;
								
				li, lh {
					display: flex;
					flex-direction: row;
					padding: 3px;
					div {
						flex: 1 1 0;
						
						&:first-child {
							flex: 2 1 0;
						}
					}
				}

				lh {
					font-weight: 800;
					padding-bottom: 20px;
				}
			}
		}
	}
}

#analyser {
	@include no-select;
	background: white;
	position: fixed;

	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	display: flex;
	flex-direction: column;
	
	#analyser-resize-handler-vertical {
		border-left: 3px solid;
		cursor: ew-resize;
	}
	
	#analyser-resize-handler-horizontal {
		border-top: 3px solid;
		cursor: ns-resize;
	}
	
	#timeline-hover {
		visibility: hidden;
		width: 150px;
		background-color: #555;
		color: #fff;
		text-align: left;
		padding: 5px;
		border-radius: 6px;
		
		position: absolute;
		z-index: 1;
		opacity: 0;
		
		&:after {
			content: "";
			position: absolute;
			top: 15px;
			right: 100%;
			margin-top: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: transparent #555 transparent transparent;
		}	
	}
	
	#timeline-context-menu {
		visibility: hidden;		
		position: absolute;
		z-index: 1;
		opacity: 0;
	}
	
	.analyser-top {
		flex: 1 1 auto;
		display: flex;
		flex-direction: row;
		.analyser-video {
			box-sizing: border-box;
			flex: 1 1 auto;
			width: 200px;
			background-color: black;
			
			#analyser-video {
				width: 100%;
				height: 100%;
			}
		}
		
		.analyser-toolview {
			box-sizing: border-box;
			flex: 1 1 auto;
			.analyser-toolview-buttons {
				width: 100%;
				text-align: center;
				
				.btn {
					width: inherit;
				}
			}
			
			#analyser-toolview {
				width: 100%;
				height: calc(100% - 31px);

				#analyser-toolview-playlist-buttons {
					width: 100%;
					display: flex;
					height: 30px;
					border-bottom: 1px black solid;
										
					& > div, & > label { 
						padding: 8px;
						cursor: pointer;
						font-size: 0.9em;
						color: #999;
					}
				}
				
				#analyser-toolview-content-scroll {
					height: calc(100% - 30px);
					overflow: auto;
					
					#analyser-toolview-content {
						width: 100%;
					}
				}
			}
		}
	}
	
	.analyser-bottom {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		.analyser-toolbar {
			flex-grow: 0;
			height: 40px;
			min-height: 40px;
			display: flex;
			flex-direction: row;
			
			.analyser-toolbar-left {
				flex-grow: 1;
				display: flex;
				flex-direction: row;
			}
			
			.analyser-toolbar-right {
				display: flex;
				flex-direction: row;
			}
			
			.analyser-toolbar-left > div, .analyser-toolbar-left > label, .analyser-toolbar-right > div {
				padding: 12px;
				cursor: pointer;
				font-size: 0.9em;
				color: #999;
			}
		}
		
		.analyser-timeline {
			flex-grow: 1;
						
			#analyser-timeline-scroll, #analyser-titles-scroll {
				position: absolute;
				display: block;
				overflow: auto;
				
				bottom: 0;
				left: 0;
				top: 0;
				right: 0;
				
				#analyser-timeline-scrollable, #analyser-titles-scrollable {
					position: relative;
					display: block;
				}
			}
						
			#analyser-titles-scroll {
				scrollbar-width: none; /* Firefox 64 */
				-ms-overflow-style: none; /* IE 11 */
				&::-webkit-scrollbar { /** Webkit */
					display: none;
				}
			}
			
			#analyser-timeline {

			}
		}
		
		.analyser-bottom-toolbar {
			flex-grow: 0;
			height: 40px;
			min-height: 40px;
			display: flex;
			flex-direction: row;
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;		
			.analyser-bottom-mode {
				flex-grow: 1;
				
				.form-switch {
					margin-left: 10px;
					margin-top: 9px;
					position: relative;
					display: inline-block;
					width: 36px;
					height: 20px;
					
					input {
						opacity: 0;
						width: 0;
						height: 0;
						
						&:checked + .form-slider {
							&:before {
								-webkit-transform: translateX(14px);
								-ms-transform: translateX(14px);
								transform: translateX(14px);
							}
							i.fa-bolt {
								opacity: 1;
							}
							i.fa-tags {
								opacity: 0;
							}
						}
					}
					
					.form-slider {
						position: absolute;
						cursor: pointer;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background-color: gray;
						border-radius: 20px;
						
						i {
							font-size: 9px;
							&.fa-bolt {
								padding-left: 8px;
								opacity: 0;
							}
							
							&.fa-tags {
								opacity: 1;
								padding-left: 19px;
								top: -17px;
								position: relative;
							}
						}
						
						&:before {
							position: absolute;
							content: "";
							height: 12px;
							width: 12px;
							left: 4px;
							bottom: 4px;
							background-color: white;
							border-radius: 50%;
						}
					}
					
				}
			}
			
			.analyser-bottom-zoomslider {
				input {
					padding: 13px 0;
					margin-right: 10px;
				}
			}
		}
	}		
}