.inline-files {
	@include no-select;
	
	ul {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		li {
			padding: 10px 2px;
			
			&.inline-file-upload {
				input[type="file"] {
					display: none;
				}
				
				label {
					color: inherit;
				}
			}
			
			.inline-file-img-container {
				margin: 0 10px;
				position: relative;
				width:60px;
				height:60px;
			
				&[data-template]:hover {
					cursor: pointer;
				}
				
				&:hover {
					cursor: pointer;
				}

				i.fa-times-circle {
					top: 0px;
					border-radius: 50%;
					background: white;
					right: -5px;
					position: absolute;
				}
				
				.inline-file-img {
					@include rounded;
					width:60px;
					height:60px;
					object-fit: cover;
				}
				
				.inline-file-icon {
					display: inline-block;
					@include rounded;
					margin-bottom: 2px;
					width:60px;
					height:60px;
					text-align: center;
					i {
						font-size: 2.3em;
						line-height: 60px;
					}
				}
			}
			
			.inline-file-name {
				padding-top: 5px;
				font-size: 0.7em;
				text-align: center;
				word-break: break-word;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 80px;
			}
		}
	}
}
