
.playlist-item {
	height: 40px;
	width: 100%;
	display: inline-flex;
	padding: 13px 13px;
	font-size: 0.9em;
	
	> div {
		overflow: hidden;
	}
	
	.playlist-item-times {
		width: 180px;
		min-width: 180px;
		max-width: 180px;
		font-family: $timeFont;
		padding-right: 10px;
	}
	
	.playlist-item-icon, .playlist-item-index {
		width: 20px;
		margin-right: 12px;
	}
	
	.playlist-item-name {
		padding-right: 15px;
		font-weight: 400;
	}
	
	.playlist-item-other {
		display: inline-flex;
		flex: 1 1 0;
		
		.playlist-item-events {
			font-style: italic;
			overflow: hidden;
		}
		
		.playlist-item-comment {
			padding-left: 5px;
			overflow: hidden;
		}
	}

}

	
