table {
	thead {
		tr {
			th {
				text-align: left;
				padding: 10px 20px;
			}
		}
	}
	tbody {
		tr {
			td {
				padding: 5px 20px;
			}
		}
	}
	&#tableChartContainer {
		tbody {
			tr {
				td {
					i {
						cursor: pointer;
					}
				}
			}
		}
	}

}