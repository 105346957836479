#questionnaireCategoryColors, #statisticsBenchmarks {
	i.fa-circle {
		cursor:pointer;
	}

	.form-row {

		flex-flow: row;
		flex-wrap: nowrap;
		align-content: stretch;

		i {
			width:30px;
			margin:10px;
		}
		input[type=text] {
			width: calc(50% - 80px);
			margin: auto;
		}

		&.form-row-header {
			div {
				width: calc(50% - 80px);
				margin: auto;

				&:first-child,&:last-child {
					width:30px;
					margin:10px;
				}
			}

		}
	}
}

#questionnaireAnswer {
	legend {
		white-space:pre-wrap;
	}

	hr {
		margin:10px 0;
	}

	fieldset div.form-row {
		margin-bottom:10px;
	}

	.buttons {
		display:block;
		> div {
			display:inline-block;
		}

		.btn {
			display:flex;
			padding:6px 20px 6px 12px;
			text-align:left;
			white-space: normal;

			i {
				margin-right: 10px;
			}
		}

		&.buttons-inline {
			.btn {
				display:inline-block;
			}

		}
	}

	.form-date {
		display:none;
	}
	.flatpickr-calendar {
		margin-bottom:10px;
	}
}

.mainform fieldset.questionnaireSummary {
	.question {
		border-bottom-width:1px;
		border-style:solid;
		margin-bottom: 20px;

		.question-text {
			margin-bottom: 5px;
			white-space:pre-wrap;
		}
		.question-answer {
			padding: 5px 0;
			font-size: 1em;
			white-space:pre-wrap;

			&.question-answered {
				font-weight:bold;
				font-style: italic;
			}
			.fa {
				margin-right:5px;
			}

		}

		.question-score {
			font-weight:bold;
		}
	}
}

.mainform fieldset.questionnaireBuilderAnswers {
	.form-switch {
		margin-top: auto;
		margin-bottom: auto;
		margin-right: 4px;

		input:checked+.form-slider {
			background-color:green;
		}
	}
}

.calculation-questions {
	.btn {
		max-width:200px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
#calculation {
	width: 100%;
	min-height: 100px;
	position: relative;
	border-bottom-width: 1px;
	border-bottom-style: solid;

	.calculation-container {
		padding: 40px;
		cursor: text;
		position: relative;

		.calculation-cursor {
			display: none;
			vertical-align: middle;
			height: 20px;
			width: 3px;
			background-color: rgba(255,255,255,.6);
			animation: blink .5s ease alternate infinite;

			&.active {
				display: inline-block;
			}
		}

		@keyframes blink {
		  100% {
		    opacity: 0;
		  }
		}

		.calculation-item {
			display: inline-block;
			vertical-align: middle;
			white-space: nowrap;
			margin: 3px;
			background-color: #999;
			border-radius: 5px;
			color: #333;
			padding: 0px 10px;

			&.calculation-item-bracket {
				padding: 0px 4px;
				color: green;
				font-size: 1.7em;
				background: transparent;
			}

			&.calculation-question {
				padding: 4px 10px;
				max-width:200px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&.calculation-item-operator {
				color: red;
				padding: 4px 10px;
			}

			.calculation-item-char {
				display: inline-block;
				padding-top: 4px;
				padding-bottom: 4px;
			}
		}
	}

	textarea {
		width: 100%;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		opacity: 0;
		background-color: transparent;
		//color: #fff;
		color: transparant;
		font-size: 0;
		text-indent: -10000px;
	}

	.calculation-validation {
		float:right;
		.calculation-validation-valid {
			display:none;
		}
		.calculation-validation-invalid {
			display:none;
		}
	}
}

// Fix for language en vs gb
.flag-icon-en {
	background-image: url(/flags/4x3/gb.svg);
	&.flag-icon-squared {
		background-image: url(/flags/1x1/gb.svg);
	}
}

.flag-icon-rounded {
	@include rounded;
}

#externalLink {
	padding-bottom: 10px;
}

.questionnaire-progress {
	margin-bottom: 10px;
	border-radius: 10px;

	.questionnaire-progress-inner {
		border-radius: 10px;
		padding: 5px;
		text-align: center;
		transition: width 2s;
	}
}