.native-file {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	img {
		width: 100%;
	}
	.btn {
		position: absolute;
		padding: 15px 30px;
		border-radius: 10px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
