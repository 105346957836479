.send-comment {
	cursor: pointer;
}

.comments {
	.comments-container {
		.jodit-container {
			min-height: auto !important;
			height: auto !important;

			.jodit-workplace {
				min-height: auto !important;
				height: auto !important;
			}

			.jodit-status-bar {
				display: none !important;
			}

			.jodit-wysiwyg {
				min-height: auto !important;
			}
		}

		.comments-leave {
			.comments-upload-file {
				display: none;
			}
			.comments-insert-video-time {
				display: none;
			}
			.comments-typing {
				display: none;

				.comments-typing-users {
					span {
						font-weight: 100;
						font-style: italic;
						padding-left: 5px;
					}
				}
			}
		}

		.comments-all {
			.comment-text {
				strong {
					font-weight: bold;
				}

				em {
					font-style: italic;
				}
			}

			.comments-more {
				margin-top: 5px;
				cursor: pointer;
			}

			.comment-container {
				&.search-comment-not-found {
					display: none;
				}

				.comment {
					margin-top: 10px;
					border-radius: 5px;
					padding: 10px;
					display: flex;
					flex-direction: column;

					margin-right: 20px;

					&.comment-self {
						margin-right: 0;
						margin-left: 20px;
					}

					.comment-replyTo {
						white-space: nowrap;
						overflow: hidden;

						.comment-reply {
							cursor: pointer;

							display: flex;
							padding-left: 10px;
							padding-right: 10px;
							padding-bottom: 10px;

							i {
								padding-right: 5px;
							}

							.comment-user {
								font-weight: 100;
								padding-right: 5px;
							}

							.comment-text {
								line-height: 1em;
								text-overflow: ellipsis;
								overflow: hidden;
							}
						}
					}

					.comment-body {
						display: flex;
						flex-direction: row;

						table {
							table-layout: fixed;
							width: 100%;
							tr :is(th, td) {
								padding: 5px;
								border-width: 1px;
								border-style: solid;
								text-align: left;
							}
						}

						.comment-left {
							flex-grow: 0;
							padding-right: 10px;
							.comment-user-img {
								@include rounded;
								width: 40px;
								height: 40px;
								object-fit: cover;

								.comment-user-icon {
									display: inline-block;
									@include rounded;
									margin-bottom: 2px;
									width: 40px;
									height: 40px;
									text-align: center;

									i {
										font-size: 1.3em;
										line-height: 40px;
									}
								}
							}
						}

						.comment-right {
							flex-grow: 1;
							.comment-user {
								padding-top: 5px;
								font-weight: 100;
							}
							.comment-text {
								padding-top: 5px;
								white-space: pre-line;
								word-break: break-word;
							}
							.comment-footer {
								padding-top: 5px;
								display: flex;
								align-items: flex-end;
								flex-direction: row-reverse;

								.comment-remove {
									padding-right: 5px;
									cursor: pointer;
								}
								.comment-reply {
									padding-right: 5px;
									cursor: pointer;
								}
								.comment-time {
									font-weight: 100;
								}
							}
							.comment-file {
								li {
									display: flex;
									.comment-file-name {
										margin-top: 20px;
										height: 1em;
										overflow: hidden;
										white-space: pre-line;
										text-overflow: ellipsis;
									}
								}

								.comment-file-img-container {
									margin: 0 10px;
									position: relative;
									width: 60px;
									height: 60px;

									&[data-template]:hover {
										cursor: pointer;
									}

									&:hover {
										cursor: pointer;
									}

									.comment-file-img {
										@include rounded;
										width: 60px;
										height: 60px;
										object-fit: cover;
									}

									.comment-file-icon {
										display: inline-block;
										@include rounded;
										margin-bottom: 2px;
										width: 60px;
										height: 60px;
										text-align: center;
										i {
											font-size: 2.3em;
											line-height: 60px;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		&.comments-reverse {
			display: flex;
			flex-direction: column-reverse;
			.comments-all {
				display: flex;
				flex-direction: column-reverse;
				margin-bottom: 20px;
			}
			.comments-shift-return {
				text-align: right;
			}
		}
		.comments-reverse-button {
			text-align: right;
		}
		.comments-shift-return {
			font-style: italic;
		}
	}
}
