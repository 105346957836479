#uploadFileSelectorLink {
	text-decoration: underline;
	&:hover {
		cursor: pointer;
	}
}

#upload-buttons {
	display: flex;
	flex-direction: row;
	padding: 5px;

	@media all and (max-width: $smallScreen) {
		display: block;
		text-align: center;
	}
	
	> div {
		display: flex;
		flex-grow: 0;
	
		&:last-child {
			flex-grow: 1;
			justify-content: flex-end;
		}

		@media all and (max-width: $smallScreen) {
			justify-content: center !important;
			padding-bottom: 5px;
		}
	}
}

#upload-stats {
	padding-left: 5px;
	padding-top: 5px;
}

#drop-zone {
	margin: 5px;
	
	&.drop-zone {
		padding: 20px;
		text-align: center;
		
		i {
			padding: 5px;
			color: #fff;
			font-size: 30px;
		}
	}
}

.card {
	button {
		margin-right: 2px;
		margin-left: 2px;
	}
}

.menuProgress {
	padding-top: 3px;
	font-size: 0.7em;	
}
