#help {
	
	&.searching {
		> * {
			display: none;
		}
		
		> .search-found {
			display: block;
		}
	}
	
	> h2 {
		font-family: $font;
		font-size: 1.4em;
		margin-bottom: 10px;
		width: 100%;
	}
	
	> h3 {
		font-family: $font;
		font-size: 1.2em;
		margin-bottom: 10px;
		width: 100%;
		
		i.fa {
			margin-right: 6px;
		}
	}
	
	> div {
		margin-bottom: 15px;
	
		> i {
			font-style: italic;
		}
		
		> b {
			font-weight: bold;
		}
		
		> u {
			text-decoration: underline;
		}
		
		> ul {
			padding-top: 5px;
			padding-bottom: 5px;
			
			&:not(.legend) {
				list-style-type: circle;

				& li {
					margin-left: 20px;
				}
	
			}

			i.fa {
				margin-right: 10px;
			}
			
			li {
				margin-bottom: 3px;
			}

		}
	
	}

	> div.highlight {
		border: 1px solid ;
		padding: 20px;
		margin: 20px;
	}
	
}

div.help {
	border: 1px solid;
	border-left: 10px solid;
	padding: 15px;

	margin-bottom: 15px;
	margin-top: 0px;
	line-height: 20px;
	
	> .help {
		border: none;
		padding: 0;
		margin:	0;
	}
	
	> i {
		font-style: italic;
	}
	
	> b {
		font-weight: bold;
	}
	
	> u {
		text-decoration: underline;
	}
	
	> ul {
		padding-top: 5px;
		padding-bottom: 5px;
		
		&:not(.legend) {
			list-style-type: circle;

			& li {
				margin-left: 20px;
			}

		}
		
		i.fa {
			margin-right: 10px;
		}
	}
}

.form-row {
	div.help {
		margin-bottom: 0px;
		margin-top: 15px;
	}
}