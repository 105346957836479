// Mixins and Placeholders
%clearfix {
	&:after, &:before {
		content: '';
		display: block;
		width: 100%;
		clear: both;
	}
}

@mixin prefix($prop, $val) {
	@each $prefix in '-webkit-', '-moz-', '-ms-', '' {
		#{$prefix}#{$prop}: $val;
	}
}

.notification-center {
	.notification-header {
		font-weight: bold;
	}
	
	.notification {
		&[data-template] {
			cursor: pointer;
		}
		.notification-header {
			border-top-right-radius: 5px;
			border-top-left-radius: 5px;
			padding: 5px 10px;
			
			.home-buttons {
				float: right;
				font-size: 0.8em;
			}
		}
		.notification-content {
			padding: 10px;
			
			&.notification-conversation {
				max-height: 105px;
			}
			
			.notification-inner-content {
				margin-right: 20px;
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;			  
				text-overflow: ellipsis;
			}
			
			.notification-user {
				font-weight: 200;
			}
		}
		
		.home-calendar {
			padding-top: 10px;
			padding-bottom: 10px;
			display: flex;
			flex-direction: column;
		}
		
		margin-top: 10px;
		margin-bottom: 10px;
		border-radius: 5px;
		@include prefix(box-shadow, 0 3px 0 rgba(0,0,0,0.1));
		@include prefix(border-radius, 5px);
		@include prefix(transition, all .3s ease);
		
	}
}

.home-calendar {
	padding-top: 20px;
	
	.home-appointment {
		cursor: pointer;
		padding-bottom: 10px;
		
		.home-appointment-start {
			font-weight: 100;
		}
		
		.home-appointment-name {
			padding-top: 5px;
			padding-bottom: 5px;
		}
		
		.home-appointment-name, .home-appointment-description, .home-appointment-location {
			padding-left: 20px;
		}
		
		.home-appointment-description, .home-appointment-location {
			font-weight: 100;
		}
	}
}

// Home
#home {
	width: 100%;
	margin: 30px auto;
	position: relative;
	padding: 0 10px;
	@include prefix(transition, all .4s ease);

	&:before {
		content:"";
		width: 3px;
		height: 100%;
		left: 50%;
		top: 2px;
		position: absolute;
	}

	&:after {
		content: "";
		clear: both;
		display: table;
		width: 100%;
	}
	
	.home-item {
		
		&.hidden {
			display: none;
		}
		
		.btn {
			white-space: pre-line;
		}
		
		margin-bottom: 50px;
		position: relative;
		@extend %clearfix;

		.home-icon {
			width: 50px;
			height: 50px;
			position: absolute;
			top: 0;
			left: 50%;
			overflow: hidden;
			margin-left: -23px;
			@include rounded;

			i {
				font-size: 1.5em;
				position: relative;
				top: 12px;
				left: 10px;
			}
			
			&.home-year {
				i {
					font-size: 1em;
					top: 14px;
					left: 7px;
				}
			}
		}

		.home-content {
						
			width: 45%;
			background: #fff;
			padding: 20px;
			
			div[data-key="announcement"] {
				ul {
					list-style: disc;
				}
				
				ol {
					list-style: decimal;
				}
				
				ul, ol {
					margin-block-start: 1em;
					margin-block-end: 1em;
					margin-inline-start: 0px;
					margin-inline-end: 0px;
					padding-inline-start: 40px;
				}
			}
			
			&:not(.home-year) {
				@include prefix(box-shadow, 0 3px 0 rgba(0,0,0,0.1));
				@include prefix(border-radius, 5px);
				@include prefix(transition, all .3s ease);
			}
			
			&.home-year {
				background: rgba(0,0,0,0);
				&:before {
					display: none;
				}
			}
			
			.image {
				text-align: center;
				img {
					max-height: 100px;
				}
			}
			
			.home-more {
				cursor: pointer;
			}
			
			.home-more + form { 
				display:none; 
			}
			
			
			p {
				padding: 10px 0;
				white-space: pre-wrap;
				
				&.description {
					font-weight: 100;
				}
			}
			
			.home-header {
				@include prefix(border-radius, 3px 3px 0 0);				
				display: flex;				
				padding: 15px;
				margin: -20px -20px 10px -20px;
				color: #fff;

				h2 {
					flex-grow: 1;
					font-weight: 300;
					word-break: break-word;
				}

				div {
					flex-shrink: 0;
				}
				
				&.home-pinned {
					.home-pin {
						display: none;
					}
				}
				
				&:not(.home-pinned){
					.home-unpin {
						display: none;
					}
				}
												
				.home-buttons {
					align-self: flex-start;
					i {
						margin-left: 10px;
						cursor: pointer;
					}
				}
			}
			

			&:before {
				content: '';
				position: absolute;
				left: 45%;
				top: 20px;
				width: 0; 
				height: 0; 
				border-top: 7px solid transparent;
				border-bottom: 7px solid transparent; 
			}

			&.right {
				float: right;

				&:before {
					content: '';
					right: 45%;
					left: inherit;
					border-left: 0;
				}
			}

			.scrollable {
				overflow-y: auto;
			}
			
			form.mainform {
				.files-created {
					display: none;
				}
				.conversion-status {
					display: none;
				}
			}
		}
	}
}


@media screen and (max-width: 768px) {
	#home {
		margin: 30px;
		padding: 0px;
		width: 90%;
		&:before {
			left: 0;
		}
		
		.home-item {
			.home-content {
				width: 90%;
				float: right;
				
				&:before, &.right:before {
					left: 10%;
					margin-left: -6px;
					border-left: 0;
				}
			}

			.home-icon {
				left: 0;
			}
		}
	}
}