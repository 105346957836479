


.questionnaire-reports {
	.questionnaire-report-question {
		margin-bottom:20px;

		.progress {
			width:100%;
			height:20px;
			border: 1px solid #1a1a1a;
			background-color: #323232;
			margin-bottom:5px;
			position: relative;

			.progress-inner {
				height:100%;
				background-color:#925bca;
			}
			.progress-inner-text {
				position:absolute;
				padding: 1px 5px;
			}
		}

		.questionnaire-report-title {
			font-size:1.4em;
			border-bottom: 1px solid #925bca;
			margin-bottom:5px;
		}


		.questionnaire-report-answers {
			.questionnaire-report-answer {

			}
		}
	}

	.rows-horizontal {
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-content:stretch;

		.form-row {
			width: auto;
			flex: 1 auto;
			margin-left:15px;
			&:first-child {
				margin-left:0;
			}
		}
	}
	.questionnaire-report {
		overflow-x: auto;
		table {
			margin-bottom:20px;

			tbody {
				tr {
					td {
						white-space: nowrap;
					}
					th {
						white-space: nowrap;
						text-align:left;
						padding: 5px 20px;
						&:first-of-type {
							min-width:150px;
							/*
							max-width:300px;
							overflow: hidden;
							text-overflow: ellipsis;
							*/
						}
					}
				}
			}

			&.table-horizontal {
				tbody {
					tr {
						td {
							&:nth-child(n+3) {
								max-width: 130px;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}
					}
				}

				&.questionnaire-overview {
					tbody tr:last-child {
						border-top: none;
					}
				}
			}

			&.table-vertical {
				thead tr th span {
					bottom:100px;
					left:-15px;
					line-height:1.2em;
				}
				tbody {
					tr {
						&:nth-child(n+2) {
							td {
								&:nth-child(n+2) {
									max-width: 130px;
									overflow: hidden;
									text-overflow: ellipsis;
								}
							}
						}

					}
				}
			}


			thead {
				tr {
					th {
						height: 160px;
						width: 40px;
						min-width: 40px;
						max-width: 40px;
						position: relative;
						vertical-align: bottom;
						padding: 0;
						font-size: 12px;
						line-height: 0.8;
						
						.questionnaire-header-titles {
							display: flex;
							flex-direction: column;
							height: 100%;
							width: 100%;
							&.questionnaire-header-titles-2 > div:first-child {
								padding-left:9px
							}
							&.questionnaire-header-titles-3 > div:first-child {
								padding-left:18px
							}
							&.questionnaire-header-titles-3 > div:nth-child(2) {
								padding-left:9px
							}
						}

						> div {
							position: relative;
							top: 0px;
							left: -80px;
							height: 100%;
							-ms-transform:skew(45deg,0deg);
							-moz-transform:skew(45deg,0deg);
							-webkit-transform:skew(45deg,0deg);
							-o-transform:skew(45deg,0deg);
							transform:skew(45deg,0deg);
							overflow: overlay;
						}
						span {
							-ms-transform:skew(-45deg,0deg) rotate(45deg);
							-moz-transform:skew(-45deg,0deg) rotate(45deg);
							-webkit-transform:skew(-45deg,0deg) rotate(45deg);
							-o-transform:skew(-45deg,0deg) rotate(45deg);
							transform:skew(-45deg,0deg) rotate(45deg);
							position: absolute;
							bottom: 115px;
							left: -20px;
							display: inline-block;
							width: 100%;
							width: 85px;
							text-align: left;
							white-space: nowrap;

						}

					}
				}
			}
			tbody {
				tr {
					td {
						text-align: center;
						white-space: nowrap;
					}
				}
			}
		}
	}
}

.swal2-content {
	.questionnaire-report-answer {
		.question {
			font-weight:strong;
			white-space:pre-wrap;
		}
		.answer {
			margin-bottom:20px;
			white-space:pre-wrap;
			ul {
				display:inline-block;
				list-style: circle;
			}
		}
	}
}
