.tree > ul { 	
	@include no-select;
	margin: 1em;

	input[type="checkbox"] {
		position: absolute;
		left: -17px;
		z-index: 2;
		opacity: 0;
		cursor: pointer;
		
		& ~ ul {
			display: none;
		}
		
		&:checked ~ ul {
			display: block;
			margin-left: -1px;
		}
	}
	
	li {
		line-height: 1.2;
		position: relative;
		padding: 0 0 1em 1em;
		&.search-not-found {
			display:none;
		}
		
		&:before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: -.5em;
			display: block;
			width: 0;
			border-left: 1px solid #777;
			content: "";
		}

		&.search-last-child:before,
		&:last-child:before {
			height: 1em;
			bottom: auto;
		}
	}

	ul li {
		padding: 1em 0 0 1em;
	}
	
	& > li:last-child {
		padding-bottom: 0; 

		&:before { 
			display: none;
		}
	}
	
	.tree-info-label {
		font-weight: 100;
	}
	
	.tree-label {
				
		&.tree-label-collapsable {
			cursor: pointer;	
		}
		
		i {
			padding-left: 10px;
		}
	}

	.tree-collapsable {		
		&:before {
			background: #000;
			color: #fff;
			position: relative;
			z-index: 1;
			float: left;
			margin: 0 1em 0 -2em;
			width: 1em;
			height: 1em;
			border-radius: 1em;
			content: '+';
			text-align: center;
			line-height: .9em;
		}
		&:after {
			border-bottom: 0;
		}
		
	}

	
	.tree-non-collapsable, .tree-collapsable {		
		position: relative;
		display: inline-block;

		&:after {
			position: absolute;
			top: 0;
			left: -1.5em;
			display: block;
			height: 0.5em;
			width: 1em;
			border-bottom: 1px solid #777;
			border-left: 1px solid #777;
			border-radius: 0 0 0 .3em;
			content: '';
		}

		> div {
			display: flex;
			flex-wrap: wrap;
			
			.form-row {
				width: 200px; 
				padding-right: 5px;
				margin-bottom: 0;
			}
		}
	}


	:checked ~ .tree-collapsable:before { 
		content: '-'; 
	}
	
	:checked ~ .tree-collapsable:after {
		border-radius: 0 .3em 0 0;
		border-top: 1px solid #777;
		border-right: 1px solid #777;
		border-bottom: 0;
		border-left: 0;
		bottom: 0;
		top: 0.5em;
		height: auto;
	}
	
	.tree_custom {
		display: block;
		background: #eee;
		padding: 1em;
		border-radius: 0.3em;
	}

}
