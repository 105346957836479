.image {
	overflow: hidden;

	cursor: grab;

	&.can-zoom_in {
		cursor: zoom-in;
	}

	&.is-dragging {
		cursor: grabbing;
	}

	canvas {
		position: absolute;
	}
	img {
		max-width: 100%;
		position: relative;
		z-index: 1;
	}
}

.pdf {
	width: 100%;
	height: 400px;
}

.video-js {
	width: 100%;
}

.files-created {
	padding: 4px;
	padding-top: 10px;
	padding-bottom: 0px;
	font-size: 0.8em;
}

.conversion-status {
	padding-top: 8px;
	padding-bottom: 10px;

	font-size: 0.8em;

	> div {
		padding: 4px;
	}
}

.pdf-preview {
	padding-top: 10px;
	padding-bottom: 10px;

	font-size: 0.8em;
}

.vjs-full-window {
	position: relative;
	overflow: hidden;
	header,
	.sidebar {
		display: none;
	}
}
