.pathbar {
	width: 100%;
	font-size: 0.8em;
	margin-bottom: 10px;

	li {
		display: inline;
		
		&+li:before {
			padding: 3px;
			content: "/";
		}
		
		&:hover {
			cursor: pointer;
		}
	  
	}
	
}