.adminpage {
	max-width: 100%;

	.convertor-master, .download-master {
		display: flex;
		flex-direction: column;
		padding-bottom: 20px;

		#convertor-master-host, #convertor-master-pm2, #download-master-host, #download-master-pm2 {
			font-weight: bold;
		}
	}

	.convertor-stats, .download-stats, .storage-stats {
		display: flex;
		flex-direction: column;
		padding-bottom: 20px;
	}

	.header {
		font-size: larger;
		font-weight: bold;
	}

	ol {
		padding: 30px;
		list-style-type: decimal;
	}

	ol li {
		margin: 0 10px;
		padding: 0 10px;
	}

	.convertor-servers, .download-servers, .storage-servers, .storage-mountpoints {
		display: flex;
		flex-flow: row wrap;

		.server, .mountpoint {
			padding: 20px;
			margin: 5px;
			border: 1px solid;
			min-width: 275px;

			.host {
				font-size: larger;
				font-weight: bold;
			}

			.uuid {
				padding-bottom: 10px;
			}
		}
		padding-bottom: 20px;
	}

}