.portfolioLayout-pdf {
	#header, #sidebar {
		display:none;
	}
	.page-wrap main, main {
		padding:0 !important;
		transition: none;
	}
}

#portfolioEditor, #portfolioLayout {
	.section {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-content: stretch;
		align-items: stretch;
		position: relative;
	}

	img.portfolio-image {
		max-width:100%;
	}

	.portfolio-image+input {
		display: block;
		padding-top: 5px;
	}

	.tactical-field {
		text-align: center;
	}
}

#portfolioEditor{
	.pagebreak {
		color: gray;
		padding-bottom: 5px;
		border-bottom: dashed 1px;
	}
}
#portfolioLayout {
	.pagebreak {
		page-break-after: always;
		border:0
	}
}
#portfolioLayout {
	.section {
		display: block;
	}

	.jodit-container .jodit-status-bar {
		display: none !important;
	}
	.jodit-container, .jodit-workplace, .jodit-wysiwyg_mode {
		background-color: transparent !important;
	}
	.readonly {
		.jodit-workplace {
			border: 0;
		}
		.jodit-wysiwyg_mode {
			padding: 0;
		}
	}

	@media only screen and (min-width: $largeScreen) {
		.section {
			display: flex;
		}
	}

	> div:not(.section), .section > div, .section-divider > div {
		position: relative;
		width: 100%;
		align-items: flex-start;

		&:not(.section) {
			justify-content: flex-start;
		}

		&.form-row-editor,.form-row-editor {
			align-items: normal;
		}

		.form-row:not(.form-row-switch):not(.form-row-editor) {
			flex-flow: row wrap-reverse;
		}

		> table, > .portfolio-linechart {
			margin-bottom:20px;
		}
	}




	.section-2 {
		justify-content: space-between;
		.section-divider {
			width: 100%;

			@media only screen and (min-width: $largeScreen) {
				width: 49%;
			}
		}
	}

	.section-3 {
		justify-content: space-between;

		.section-divider {
			width: 100%;

			@media only screen and (min-width: $largeScreen) {
				width: 32%;
			}
		}
	}

	hr {
		margin-top: -15px;
	}

	.portfolio-linechart {
		height:300px;
	}

}

#portfolioEditor {

	.section {
		border-style: dashed;
		border-width: 1px;
		border-radius: 5px;
		padding-top: 20px;
		margin-bottom: 5px;
		border-left-width: 5px;
		border-left-style: solid;

		&:hover {
			border-style: solid;
		}
	}

	> div:not(.section):not(.add-container), .section > div, .section-divider > div:not(.add-container), div.add {
		flex: 1 1 0px;
		position: relative;

		&:not(.edit) {
			margin-top: 5px;
			margin-bottom: 5px;
			padding: 10px;
		}

		&:not(.section-divider):not(.edit) {
			border-radius: 5px;
			border-style: dashed;
			border-width: 1px;
			border-left-width: 5px;
			border-left-style: solid;

			&:hover {
				border-style: solid;
			}

		}

		> i {
			vertical-align: top;
		}
	}

	div.edit {
		z-index: 10000001;
		> i {
			opacity: 0.4;
		}
		display: inline-block;
		background: none !important;
		border: none !important;
		cursor: pointer;
		position: absolute;
		top: 10px;
		right: 10px;
		.options > i {
			padding-right: 10px;
		}
	}

	div.portfolio-star-container {
		padding-top: 5px;
		padding-bottom: 5px;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		i {
			cursor: pointer;
		}
	}

	div.portfolio-star-buttons,div.tactical-field-buttons {
		@include no-select;
		margin-top: 15px;

		i {
			cursor: pointer;
			margin-right: 5px;
		}
	}

	div.portfolio-checkbox {
		div.form-row-switch {
			display: flex;
		}
	}

	div.add-container {
		div.add {
			display: flex;
			cursor: pointer;
			padding: 5px;

			> i {
				padding: 5px;
			}

			.options > div {
				display: inline-block;
				padding: 5px;

				&:not(:last-child) {
					border-right-width: 1px;
					border-right-style: dashed;
				}
			}

			.options > div > i {
				text-align: center;
				width: 30px;
			}

			&.add-inline {
				display: none;
			}
		}

		.add-reveal {
			z-index: 10000000;
			position: relative;
			opacity: 0;

			&:hover {
				opacity: 1;
			}

			> div {
				position: absolute;
				border-radius: 50%;
				text-align: center;
				width: 100%;
				top: -15px;
				> i {
					cursor: pointer;
					border-radius: 50%;
					background: white;
					font-size: 1.5em;
				}
			}
		}
	}

	[contenteditable="true"]:empty:not(:focus):before {
		content: attr(data-placeholder);
		pointer-events: none;
	}

	.portfolio-item {
		> div {
			display: inline-block;
		}
	}

	div.portfolio-hr hr {
		margin-top: 7px;
		margin-bottom: 7px;
		margin-right: 105px;
	}

	.select-add {
		width: 100%;
	}

	div.portfolio-select-option {
		i.fa-check-circle {
			cursor: pointer;
			padding-right: 10px;
		}
	}

	select::after {
		content: '\f0dc';
		font-family: 'Font Awesome 5 Free';
		font-weight: bold;
		position: absolute;
		right: 4px;
		bottom: 6px;
		pointer-events: none;
	}
}
