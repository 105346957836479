.user-selector {
	@include no-select;
	
	ul.user-selector-selected, ul.user-selector-inherited {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		min-height: 96px;

		li {
			padding: 10px 10px;

			.usage-progress {							
				.usage-top {
					width: 60px;
					height: 60px;
					@include rounded;
					.user-selector-img-container {
						margin: 5px 0 0 5px;
						top: 5px;
						
						.user-selector-img, .user-selector-icon {
							width: 50px;
							height: 50px;
							i {
								font-size: 1.9em;
								position: relative;
								top: -5px;
							}
						} 
		
					}
				}
			}
			
			.user-selector-img-container {
				margin: 0 10px;
				position: relative;
				width:60px;
				height:60px;
			
				&[data-template]:hover {
					cursor: pointer;
				}
				
				&:hover {
					cursor: pointer;
					i.fa-lock,i.fa-lock-open {
						display:none;
					}
				}

				i.fa-times-circle,i.fa-lock,i.fa-lock-open {
					top: 0px;
					border-radius: 50%;
					background: white;
					right: -5px;
					position: absolute;
				}

				i.fa-check-circle {
					display: block;
					top: 0px;
					border-radius: 50%;
					background: white;
					right: -5px;
					position: absolute;
				}

				.user-selector-img {
					@include rounded;
					width:60px;
					height:60px;
					object-fit: cover;
				}
				
				.flag-icon {
					@include rounded;
					width:60px;
					height:60px;
				}

				.user-selector-icon {
					display: inline-block;
					@include rounded;
					margin-bottom: 2px;
					width:60px;
					height:60px;
					text-align: center;
					i {
						font-size: 2.3em;
						line-height: 60px;
					}
				}
			}
			
			.user-selector-name {
				padding-top: 5px;
				font-size: 0.7em;
				text-align: center;
				word-break: break-word;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 80px;
			}
		}
	}


	ul.user-selector-users, ul.user-selector-groups, ul.user-selector-languages {
		display: flex;
		flex-direction: column;
		// flex-wrap: wrap;
		
		li.user-selector-invisible {
			display: none;
		}

		li label {						
			width: auto !important;
			padding: 10px;
			display: flex !important;
			flex-direction: row;
			
			&:hover {
				cursor: pointer;
			}
			
			.user-selector-img-container {
				position: relative;
				
				input[type="checkbox"] {
					display: none;
					&:checked + i.fa-check-circle {
						display: inline-block;
					}
				}
				
				i.fa-check-circle {
					display: none;
					top: 0px;
					border-radius: 50%;
					background: white;
					right: -5px;
					position: absolute;
				}
				
				i.fa-lock {
					display: inline-block;
					top: 0px;
					border-radius: 50%;
					right: -5px;
					position: absolute;
				}
				
				.user-selector-img {
					@include rounded;
					width:40px;
					height:40px;
					object-fit: cover;
				}
				
				.flag-icon {
					@include rounded;
					width:40px;
					height:40px;
				}
				
				.user-selector-icon {
					display: inline-block;
					@include rounded;
					margin-bottom: 2px;
					width:40px;
					height:40px;
					text-align: center;
					i {
						font-size: 1.5em;
						line-height: 40px;
					}
				}
			}
			
			.user-selector-info {
				padding-left: 10px;
			}
			
			.user-selector-name {
				padding-top: 5px;
				text-align: left;
				overflow: hidden;
				word-break: break-word;
				text-overflow: ellipsis;
				max-width: 100%;
			}
			
			.user-selector-organisations {
				font-size: 0.7em;
			}
		}
			
	}
}
