.tactical-field {
	
	&:not(.readonly) {
		.tactical-field-player,	.tactical-field-line, .tactical-field-text {
			@include no-select;
			cursor: move;
		}
		.tactical-field-line {
			background-color:rgba(255,255,255,0.5);
			border-radius: 50%;
		}
	}
	.tactical-field-button-shownames {
		display:none;
	}
	&.tactical-field-hidenames {
		.tactical-field-image {
			.tactical-field-name {
				display: none;
			}
		}
		.tactical-field-button-hidenames {
			display:none;
		}
		.tactical-field-button-shownames {
			display:inline-block;
		}
	}

	.tactical-field-image {
		@include no-select;
		display: inline-block;
		border-width: 2px;
		border-style: solid;
	}

	.tactical-field-editor {
		z-index:100;
		position: absolute;
		// left:0;
		top:0;
		width:330px;
		height:100%;
		border-width: 2px;
		border-style: solid;
		
		display: flex;
		flex-direction: column;
		overflow: scroll;

		.tactical-field-settings {
			padding:10px;
			border-bottom-style: solid;
			border-bottom-width: 1px;
			border-top-style: solid;
			border-top-width: 1px;
			> div {
				display:flex;
				> input {
					width:25px;
				}
				> button {
					padding: 2px 10px;
				}
			}
		}

		.tactical-field-players {
			display: grid;
			grid-template-columns: auto auto auto auto;
			gap: 5px;
			padding: 10px 0;
			.tactical-field-type-ball {
				align-self: flex-end;
			}
		}

	}
	.tactical-field-text {
		position: absolute;
		background-color: transparent;
		font-size: 20px;
	}

	.tactical-field-line-svg {
		pointer-events: none;
		position: absolute;
		background-color:transparent;
		width:100%;
		height:100%;
		top:0;
		left:0;
		margin: 1px; /* not sure why this is needed, but the line looks better lined with the start/end points*/
		line {
			fill:none;
			stroke-linecap: round;
			&.tactical-field-line-dashed {
				stroke-linecap: butt;
				stroke-dasharray: 32, 16;
			}
		}
	}

	.tactical-field-line {
		position: absolute;
		background-color:transparent;
		width:30px;
		height:30px;
	}
	.tactical-field-player {
		background-color: transparent;
		gap: 10px;
		z-index:1;

		.tactical-field-icon {
			margin:2px;
			position: relative;
			span {
				position: absolute;
				line-height: 2em;
				text-align: center;
				width: 2em;
			}

			svg {
				font-size:2em;
				height: 1em;
				width: 1em;
				display: inline-block;
				vertical-align: middle;
				&.additional-icon {
					font-size: 1em;
					position: relative;
					right: 0.7em;
					bottom: -0.7em;
				}
			}
		}


		&.tactical-field-type-ball {
			.tactical-field-icon {
				font-size:0.65em;
			}
		}
		
		&.tactical-field-type-text {
			.tactical-field-icon {
				stroke:#000000;
				fill:#ffffff;
				color: #000000;
			}
			.tactical-field-name {
				color:#ffffff;
			}
		}

		.tactical-field-name {
			// margin-left: 10px;
		}
	}
	.tactical-field-image {
		position: relative;
		.tactical-field-player {
			position: absolute;
		}

	}

}
.swal-form {
	.additional-icons {
		margin-top:0.5em;
		> label {
			display: flex;
			flex-flow: column;
			align-items: center;
			min-width: 4em;
			padding:10px;
		}
		input {
			display:none;
		}
		
		input[type="radio"]:checked + label {
			border: 2px solid #d9d9d9;
			border-radius: 10px;
			padding:8px;
		}

		svg {
			font-size:2em;
			height: 1em;
			width: 1em;
			display: inline-block;
			vertical-align: middle;
			&.additional-icon {
				font-size: 1em;
				position: relative;
				right: 1em;
				margin-right:-1em;
				bottom: -0.7em;
			}
		}
	}
}

.tactical-field-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	.tactical-field-trash,.tactical-field-trash-push {
		opacity: 0;
		width: 100px;
		margin: 0 10px;
	}
	.tactical-field-trash {
		line-height: 20px;
		background-color:red;
		border-radius: 10px;
	}
	button {
		padding:5px 10px;
		font-size:0.7em;
	}
	
}