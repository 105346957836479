.btn {
	@include no-select;
	display: inline-block;
	margin-bottom: 0; // For input.btn
	font-weight: bold;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
	border: 1px solid transparent;
	border-radius:4px;
	padding: 6px 12px;

	word-wrap: break-word;
	overflow-wrap: break-word;
	word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}


.card-buttonbar {
	@include no-select;
	display: flex;

	@media all and (max-width: $smallScreen) {
		display: block;
		text-align: center;
	}

	.card-buttonset:not(:first-child){
		margin-left: 5px;
	}

}

.card-buttonset {
	@include no-select;
	display: inline-flex;

	.btn {
		width: 69px;
	}

	.form-daterange {
		width: auto;
	}

	button, .btn {
		&:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		&:not(:first-child) {
			margin-left: -1px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	span {
		padding-left: 5px;
		@media all and (max-width: 1300px) {
			display: none;
		}
	}
}

input[type=submit], button {
	@include no-select;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
	border-radius:6px;
	display:inline-block;
	cursor:pointer;
	font-family:Arial;
	font-size:15px;
	padding:6px 24px;
	text-decoration:none;

	&:active {
		position:relative;
		top:1px;
	}
}

