#highlightscheme-editor-window {
	display: flex;
	flex-direction: row;

	.highlightscheme-editor-form {
		max-width: calc(100% - 310px);
		flex-grow: 1;
	}

	.highlightscheme-editor-inspector {
		margin-left: 10px;
		width: 300px;
		padding: 10px;

		#highlightscheme-inspector-links {
			height: 250px;
			margin-top: 5px;
			border: 1px solid #999;
			overflow-y: scroll;
			overflow-x: hidden;

			.highlightscheme-inspector-link {
				display: flex;
				flex-direction: row;
				padding: 3px;
				cursor: pointer;
			}
		}

		.highlightscheme-inspector-buttons {
			text-align: center;
			padding-bottom: 10px;
		}

		.highlightscheme-inspector-triggers {
			height: 250px;
			margin-top: 5px;
			border: 1px solid #999;
			overflow-y: scroll;
			overflow-x: hidden;

			.highlightscheme-inspector-trigger {
				display: flex;
				flex-direction: row;
				padding: 3px;
				cursor: pointer;

				.highlightscheme-inspector-trigger-icon {
					width: 20px;
				}

				.highlightscheme-inspector-trigger-name {
					flex-grow: 1;
				}

				&.self {
					.highlightscheme-inspector-trigger-type {
						display: none;
					}
				}
			}
		}
	}
}


#highlightscheme-scroll {
	width: 100%;
	height: 700px;
	overflow: scroll;
	border: 1px solid #999;

	#highlightscheme-editor {

	}
}

#analyser-toolview {
	#highlightscheme-scroll {
		border: none;
	}
}

.alert-triggers {
	display: flex;
	flex-flow: column;
	padding-top: 20px;

	label {
		width: 100%;
		display: flex;
		justify-content: flex-end;

		select {
			padding-left: 5px;
			margin: 0 !important;
			width: 70% !important;
		}
	}
}